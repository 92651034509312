// Extracted from the sketch file
// https://developer.apple.com/design/resources/
// And also
// https://developer.apple.com/ios/human-interface-guidelines/visual-design/color/

const iOSColors = {
  red: "#FF3B30",
  orange: "#FF9500",
  yellow: "#FFCC00",
  green: "#4CD964",
  tealBlue: "#5AC8FA",
  blue: "#007AFF",
  purple: "#5856D6",
  pink: "#FF2D55",

  white: "#FFFFFF",
  customGray: "#EFEFF4",
  lightGray: "#E5E5EA",
  lightGray2: "#D1D1D6",
  midGray: "#C7C7CC",
  gray: "#8E8E93",
  black: "#000000"
};

export default iOSColors;
