// https://material.io/guidelines/style/color.html#color-usability

const materialColors = {
  blackPrimary: "rgba(0,0,0,0.87)",
  blackSecondary: "rgba(0,0,0,0.54)",
  blackTertiary: "rgba(0,0,0,0.38)",
  whitePrimary: "rgba(255,255,255,1)",
  whiteSecondary: "rgba(255,255,255,0.70)",
  whiteTertiary: "rgba(255,255,255,0.50)"
};

export default materialColors;
