const webWeights = {
  thin: {
    fontFamily: "System",
    fontWeight: "100"
  },
  ultraLight: {
    fontFamily: "System",
    fontWeight: "200"
  },
  light: {
    fontFamily: "System",
    fontWeight: "300"
  },
  regular: {
    fontFamily: "System",
    fontWeight: "400"
  },
  medium: {
    fontFamily: "System",
    fontWeight: "500"
  },
  semibold: {
    fontFamily: "System",
    fontWeight: "600"
  },
  bold: {
    fontFamily: "System",
    fontWeight: "700"
  },
  heavy: {
    fontFamily: "System",
    fontWeight: "800"
  },
  black: {
    fontFamily: "System",
    fontWeight: "900"
  }
};

export default webWeights;
